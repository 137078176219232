import { Component, Inject, ViewEncapsulation, OnInit, Input, OnDestroy, ViewChild, ElementRef } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { AppState } from '@app/store/index';
import * as LayoutActions from '@app/store/layout/layout.actions';
import * as AsideItemActions from '@app/store/aside-item/aside-item.actions';
import { SaveChild, ResetModalContent } from '../../../store/aside-item/aside-item.actions';
import * as AsideActions from '@app/store/aside/aside.actions';
import * as ViewerActions from '@app/store/viewer/viewer.actions';
import { ResourcesService } from '@app/shared/services/resources.service';
import { first } from 'rxjs/operators';
import { ConsoleUtils } from '@app/shared/helpers/console-utils';
import { environment } from '@environments/environment';
// import { PDFDocumentProxy, PDFPageProxy } from 'pdfjs-dist';

import {
    PdfViewerComponent, LinkAnnotationService, BookmarkViewService, MagnificationService, ThumbnailViewService,
    ToolbarService, NavigationService, TextSearchService, TextSelectionService, PrintService, AnnotationService, AnnotationDataFormat, FormFieldsService
} from '@syncfusion/ej2-angular-pdfviewer';
import { BooksService } from '@app/shared/services/books.service';
import { DOCUMENT } from '@angular/common';
import { EventsService } from '@app/shared/services/events.service';
import { User } from '@app/shared/models';

import { L10n, setCulture } from '@syncfusion/ej2-base';
import { DialogConfirmComponent } from '../dialog/dialog-confirm/dialog-confirm.component';
import { MatDialog } from '@angular/material';
import { resolve } from 'url';


declare var noScroll: any;

setCulture('es-ES');
L10n.load({
    "es-ES": {
        "PdfViewer": {
            'PdfViewer': "Visor Ediciones EO",
            'Cancel': "Cancelar",
            'Download file': 'Descargar archivo',
            'Download': 'Descargar',
            'File Corrupted': 'Archivo no válido',
            'File Corrupted Content': 'Contenido no válida',
            'Fit Page': 'Ajustar a la página',
            'Fit Width': 'Ajustar al acho',
            'Automatic': 'Automático',
            'Go To First Page': 'Ir a la primera página',
            'Next Page': 'Siguiente página',
            'OK': 'OK',
            'Open': 'Abrir',
            'Page Number': 'Número de página',
            'Previous Page': 'Página anterior',
            'Go To Last Page': 'Ir a la última página',
            'Zoom': 'Zoom',
            'Zoom In': 'Aumentar',
            'Zoom Out': 'Alejar',
            'Page Thumbnails': 'Previsualización de páginas',
            'Bookmarks': 'Favoritos',
            'Copy': 'Copiar',
            'Text Selection': 'Selección de texto',
            'Panning': 'Panorámica',
            'Text Search': 'Buscar texto',
            'Find in document': 'Encontrar en el documento',
            'Match case': 'Match case',
            'Apply': 'Aplicar',
            'GoToPage': 'Ir a página',
            // tslint:disable-next-line:max-line-length
            'No matches': 'Ninguna coincidencia',
            'No Text Found': 'Texto no encontrado',
            'Undo': 'Deshacer',
            'Redo': 'Rehacer',
            'Annotation': 'Anotaciones',
            'Highlight': 'Destacar',
            'Underline': 'Subrayar',
            'Strikethrough': 'Tachar',
            'Delete': 'Eliminar',
            'Opacity': 'Opacidad',
            'Color edit': 'Color edición',
            'Opacity edit': 'Opacidad edición',
            'Highlight context': 'Destacado contexto',
            'Underline context': 'Subrayado contexto',
            'Strikethrough context': 'Tachado contexto',
            // tslint:disable-next-line:max-line-length
            'Server error': 'Error de servidor',
            'Open text': 'Abrir texto',
            'First text': 'Primer texto',
            'Previous text': 'Anterior texto',
            'Next text': 'Siguiente texto',
            'Last text': 'Último texto',
            'Zoom in text': 'Aumentar en texto',
            'Zoom out text': 'Alejar en texto',
            'Selection text': 'Texto seleccionado',
            'Pan text': 'Texto plano',
            'Print text': 'Texto para impresión',
            'Search text': 'Texto búsqueda',
            'Annotation Edit text': 'Anotaciones texto editado',
            'SubmitForm': 'Enviar formulario',
            'Free Text': 'Texto libre',
            'Calibrate': 'Calibrar',
            'Add Stamp': 'Añadir marca',
            'Dynamic': 'Predefinidas',
            'Sign Here': 'De firmas',
            'Standard Business': 'De empresa',
            'Custom Stamp': 'Personalizada',
            'Add Shapes': 'Añadir figuras',
            'Add Line': 'Añadir línea',
            'Add Arrow': 'Añadir flecha',
            'Add Rectangle': 'Añadir rectángulo',
            'Add Círculo': 'Añadir círculo',
            'Add Polygon': 'Añadir polígono',
            'Comment Panel': 'Panel de anotaciones'
        }
    }
});

@Component({
    selector: 'app-modal-viewer',
    templateUrl: './modal-viewer.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [LinkAnnotationService, BookmarkViewService, MagnificationService,
        ThumbnailViewService, ToolbarService, NavigationService, AnnotationService, TextSearchService, TextSelectionService, PrintService]
})
export class ModalViewerComponent implements OnDestroy {
    // @ViewChild('pdfViewer', {static: false}) pdfViewer: ElementRef; 
    @ViewChild('pdfViewer', null)

    openModalViewer: boolean;

    openOptionsMobileViewer: boolean = false;

    isGroupMode: boolean;

    openViewerPanelResources: boolean;
    panelResourcesTypeOpen: string;

    modalContent: any;
    hasBtnOnFooter: boolean;
    hasUserChangesNotSave: boolean = false;
    hideActions: boolean;

    title: string;
    mode: string;
    fileData: any;


    customization: any = {
        author: 'Usuario',
        print: false,
        search: true,
        anotations: true,
        textSelection: true,
        bookmark: true,
        thumbnail: true,
        hyperlink: true,
        navigation: true,
        magnification: true,
        navigationToolbar: true,
        toolbar: true,
        toolbarSettings: {
            annotationToolbarItems: [
                'HighlightTool',
                'UnderlineTool',
                'StrikethroughTool',
                'ShapeTool',
                // 'CalibrateTool',
                'ColorEditTool',
                'StrokeColorEditTool',
                'ThicknessEditTool',
                'OpacityEditTool',
                'AnnotationDeleteTool',
                // 'StampAnnotationTool', 
                // 'HandWrittenSignatureTool',
                'InkAnnotationTool',
                'FreeTextAnnotationTool',
                'FontFamilyAnnotationTool',
                'FontSizeAnnotationTool',
                'FontStylesAnnotationTool',
                'FontAlignAnnotationTool',
                'FontColorAnnotationTool',
                'CommentPanelTool'
            ],
            showTooltip: true,
            // toolbarItems: ['PageNavigationTool', 'MagnificationTool', 'PanTool', 'SelectionTool', 'SearchOption', 'SubmitForm', 'CommentTool', 'FormDesignerEditTool', 'AnnotationEditTool', 'UndoRedoTool']
            toolbarItems: ['PageNavigationTool', 'MagnificationTool', 'PanTool', 'SelectionTool', 'SearchOption', 'CommentTool', 'FormDesignerEditTool', 'AnnotationEditTool', 'UndoRedoTool'],

        },
        annotationSettings: { author: 'Usuario' },
        stampSettings: { opacity: 0.3, author: 'Usuario' }
    };

    public currentBookId = null;
    public currentBook = null;
    public currentChapter = null;
    public currentIndexChapter = 1;
    public documentPdf: string = null;
    public documentPdfV2: string = null;
    // public service: string = 'http://localhost:6001/api/pdfviewer';
    // public service: string = 'http://localhost:6001/api/pdfviewer';
    public service: string = 'https://www.edicioneseo.es:6001/api/pdfviewer';
    // public service: string = 'https://pdfviewer.edicioneseo.es:6001/api/pdfviewer';
    // public service: string = 'https://localhost:44399/pdfviewer';

    // public service: string = 'https://ej2services.syncfusion.com/production/web-services/api/pdfviewer';
    // public documentPdf: string = 'PDF_Succinctly.pdf';

    public pdfviewerControl: PdfViewerComponent;
    public viewer;

    private suscriptionsToManage$: Subscription[] = [];

    isInFullScreenMode = false;
    elem: any;

    chapterResources: any = [];

    currentUser: User;

    currentPage: number = 1;

    idSessionMaintenance: any = 0;

    totalResources: number = 0;
    totalVideos: number = 0;
    totalDocuments: number = 0;
    totalImages: number = 0;
    totalLinks: number = 0;

    constructor(
        private store: Store<AppState>,
        private booksService: BooksService,
        private resourcesService: ResourcesService,
        private eventsService: EventsService,
        public dialog: MatDialog,
        @Inject(DOCUMENT) private document: any
    ) {

        this.initSuscriptions();

    }

    ngOnDestroy() {
        this.suscriptionsToManage$.forEach(subscription => subscription.unsubscribe());

        if (this.idSessionMaintenance) {
            clearInterval(this.idSessionMaintenance);
        }
    }

    initSuscriptions() {
        this.suscriptionsToManage$.push(this.store.select(state => state.viewer.isGroup).subscribe(res => {
            this.isGroupMode = res;
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.auth.user).subscribe(res => {
            this.currentUser = res;

            if (this.currentUser) {
                this.includeUserInAnnotatiosViewer();
            }
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.viewer.book).subscribe(res => {
            this.modalContent = res;
            if (this.modalContent) {
                this.init();
            }
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.layout.openModalViewer).subscribe(res => {
            this.openModalViewer = res;
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.layout.openViewerPanelResources).subscribe(res => {
            this.openViewerPanelResources = res;
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.viewer.panelView).subscribe(res => {
            this.panelResourcesTypeOpen = res;
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.viewer.totalResources).subscribe(res => {
            this.totalResources = res;
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.viewer.totalVideos).subscribe(res => {
            this.totalVideos = res;
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.viewer.totalImages).subscribe(res => {
            this.totalImages = res;
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.viewer.totalDocuments).subscribe(res => {
            this.totalDocuments = res;
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.viewer.totalLinks).subscribe(res => {
            this.totalLinks = res;
        }));


        this.eventsService.on(this.eventsService.VIEWER_CHANGE_CHAPTER).subscribe(
            (chapter) => {
                this.updateChapter(chapter);
            }
        );


    }

    initCustomization() {
    }

    includeUserInAnnotatiosViewer() {
        this.customization.stampSettings.author = this.currentUser.name;
        this.customization.annotationSettings.author = this.currentUser.name;
    }

    init() {
        this.title = this.modalContent.name;
        this.mode = this.modalContent.typeName;

        this.currentBookId = this.modalContent.id;
        this.currentBook = this.modalContent;

        const payload = { book: this.currentBook };
        this.store.dispatch(new ViewerActions.SetBook(payload));

        // this.getFile();
        this.updateChapter(null);

        this.elem = document.documentElement;

        this.initSessionMaintenance();
    }

    initSessionMaintenance() {
        this.idSessionMaintenance = setInterval(() => {
            this.updateSession();
        }, 300000);
    }

    maintenanceSessionEndpoint() {
        // console.log('maintenanceSessionEndpoint....');
        this.refreshDoc();
        // this.actioanExportTest(this.viewer);
    }

    refreshDoc() {        
        this.viewer.load(
            this.documentPdf,
            null
          );
    }

    refreshMaintenance() {
        if (this.idSessionMaintenance) {
            clearInterval(this.idSessionMaintenance);
        }
        this.initSessionMaintenance();
    }

    async actioanExportTest(viewer: any): Promise<any> {
        // console.log(viewer);
        // console.log('comprobando anotaciones');
        if (viewer && viewer.annotationCollection && viewer.annotationCollection.length) {
            // console.log('hay anotaciones');
        
            return new Promise((resolve, fail) => {
                viewer.exportAnnotationsAsObject().then(function (value) {
                    // console.log('VALUE anotations....', value)
                    return resolve(value);
                });

            });
        } else {
            // console.log('no hay anotaciones');
            return;
        }
    }

    exportAnnotation() {
        console.log('HOLA JORGE...');
        // var viewer = (<any>document.getElementById('pdfViewer')).ej2_instances[0];
        // viewer.exportAnnotationsAsObject();
        // viewer.exportAnnotationsAsObject().then(function (value) {
        //     console.log('este....',value);
        // });

       
        // this.viewer.exportAnnotation(AnnotationDataFormat.Json);

        this.viewer.exportAnnotationsAsObject().then(function (value) {
            console.log('THIS', value);
            return;
        });

        
        // this.viewer.exportAnnotationsAsObject().then(function (value) {
        //     console.log(value);
        // });
    }

    updateSession(){
        console.log('Call Function Every Five Seconds.', new Date());
        // this.updateChapter(this.currentChapter);

        // this.viewer.exportAnnotation(AnnotationDataFormat.Json);

        // this.documentPdf = "";
        // this.documentPdf = this.fileData;
        this.maintenanceSessionEndpoint();
        
      }

    updateChapter(chapter) {
        // console.log('actualizando capitulo.....');
        if (!chapter) {
            chapter = this.modalContent.book_model.bookindex[0];
        }

        this.currentChapter = chapter;

        this.currentIndexChapter = this.getIndexChapter(this.currentChapter);

        this.initResourcesByChapter();

        const payload = {
            chapter: this.currentChapter,
            resources: this.chapterResources
        };
        this.store.dispatch(new ViewerActions.SetChapter(payload));
        this.store.dispatch(new ViewerActions.SetGlobalPage(this.getGlobalPage(1)));

        this.updateUserChapterConfig();
        this.getFileChapter();
    }

    getIndexChapter(chapter) {
        let index = 0;
        let indexChapter = -1;
        this.currentBook.book_model.bookindex.forEach(element => {
            if (element.id == chapter.id) {
                indexChapter = index;
            }
            index++;

        });
        return indexChapter;
    }

    previousChapter() {
        this.updateChapter(this.currentBook.book_model.bookindex[this.currentIndexChapter - 1]);
    }

    nextChapter() {
        this.updateChapter(this.currentBook.book_model.bookindex[this.currentIndexChapter + 1]);
    }

    maxChapters() {
        return this.currentBook.book_model.bookindex.length;
    }

    getGlobalPage(page) {
        const chapterStart = this.currentChapter.page_start;

        return (chapterStart + (page - 1));

    }

    initResourcesByChapter() {
        this.chapterResources = [];
        let resources = this.currentBook.resources;
        if (!resources.length) {
            return;
        }

        const chapterStart = this.currentChapter.page_start;
        const chapterEnd = this.currentChapter.page_end;

        if (resources && resources.length) {
            resources.forEach(element => {
                if (!element.pages || !element.pages.length) {
                    return;
                }

                if (!this.resourcesService.hasPermissionToShowInViewer(element, this.currentUser)) {
                    return;
                }

                if (this.checkPagesInChapter(element, chapterStart, chapterEnd)) {
                    this.chapterResources.push(element);
                }
            });
        }
    }

    checkPagesInChapter(resource, chapterStart, chapterEnd) {
        let find = false;

        if (resource.pages && resource.pages.length) {
            resource.pages.forEach(page => {
                if (page >= chapterStart && page <= chapterEnd) {
                    find = true;
                    return;
                }
            });
        }

        return find;
    }

    setViewerPages(resource) {
        let pagesToViewer = [];
        return pagesToViewer;
    }


    reset() {
        this.mode = '';
        this.title = '';
    }

    close() {
        if (this.hasUserChangesNotSave) {
            this.confirmClose();
            return;
        }

        this.actionClose();
    }

    actionClose() {
        this.reset();

        this.closeFullscreen();

        this.store.dispatch(new AsideActions.ChangeHide(false));
        this.store.dispatch(new AsideItemActions.ResetModalContent());
        this.store.dispatch(new LayoutActions.CloseModalViewer());
        this.store.dispatch(new LayoutActions.ClosePanelViewerResources());

    }

    confirmClose() {
        const dialogRef = this.dialog.open(DialogConfirmComponent, {
            // width: '300px',
            panelClass: 'modal-custom',
            data: { name: "¿Salir sin guardar?", type: 'saveChanges' }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === 'actionSave') {
                this.saveConfig();
            }
            this.actionClose();
        });
    }

    updateUserChapterConfig() {
        this.currentChapter.userConfig = this.findUserChapterConfig()
    }

    findUserChapterConfig() {
        if (!this.currentBook.chaptersUser) {
            return this.loadUserConfig(null);
        }

        let userConfig = null;
        this.currentBook.chaptersUser.forEach(element => {
            if (element.id == this.currentChapter.id) {
                userConfig = element;
                return;
            }
        });

        return this.loadUserConfig(userConfig);
    }


    loadUserConfig(config) {
        if (!config) {
            return {
                currentPage: 1,
                customData: {},
                customDataGroup: {}
            };
        }

        return {
            currentPage: config.currentPage,
            customData: config.customData,
            customDataGroup: config.customDataGroup
        };
    }

    // getFile() {
    //     this.booksService.open(this.modalContent.id).pipe(
    //         first()).subscribe(data => {
    //             this.fileData = data.file;

    //             let isSameFile = false;
    //             if (this.documentPdf == data.file) {
    //                 isSameFile = true;
    //                 console.log('es el mismo tema que tenia+++++++++++++++++++++++++++++++');
    //             }
    //             this.documentPdf = data.file;
    //             this.viewer = (<any>document.getElementById("pdfViewer")).ej2_instances[0];
    //             this.initCustomization();

    //             if (isSameFile) {
    //                 console.log('es el mismo tema que tenia+++++++++++++++++++++++++++++++');
    //                 this.import();
    //             }
    //         });
    // }

    getFileChapter() {
        // console.log('getFileChapter', this.currentBookId, this.currentChapter.id);
        if (this.viewer) {
            // console.log('unload pdf chapter to change to other');
            this.viewer.unload();
        }
        this.booksService.openChapter(this.currentBookId, this.currentChapter.id).pipe(
            first()).subscribe(data => {
                // console.log('FILE CARGADO.......', this.currentChapter.id);
                this.fileData = data.file;

                let isSameFile = false;
                if (this.documentPdf == data.file) {
                    isSameFile = true;
                }


                this.documentPdf = data.file;

                if (!this.viewer) {
                    this.viewer = (<any>document.getElementById("pdfViewer")).ej2_instances[0];
                    this.viewer.toolbar.showAnnotationToolbar(true);
                }

                // this.viewer = (<any>document.getElementById("pdfViewer")).ej2_instances[0];

                if (isSameFile) {
                    this.removeAllAnotations();
                    this.import();
                }

                this.refreshMaintenance();
            });
    }

    info() {
        var pdfViewer = (<any>document.getElementById('pdfViewer')).ej2_instances[0];

        pdfViewer.toolbar.showToolbar(true);
    }

    toggleCustomization() {
        // this.customization.toolbar = !this.customization.toolbar;
        // this.viewer.toolbar.showToolbar(this.customization.toolbar);

        // var pdfViewer = (<any>document.getElementById('pdfViewer')).ej2_instances[0];
        this.viewer.toolbar.showAnnotationToolbar(true);


        // console.log(this.viewer);
    }

    isEmptyObject(obj: Record<string, any>): boolean {
        return Object.keys(obj).length === 0;
    }

    removeAllAnotations() {
        this.viewer.deleteAnnotations();
    }

    import() {
        // if (!this.currentChapter.userConfig.customData) {
        //     console.log("No hay anotaciones iniciales");
        //     return;
        // }
        let jsonAnnotation = null;
        let currentPage = 1;

        if (this.isGroupMode) {
            if (this.currentChapter.userConfig.customDataGroup && !this.isEmptyObject(this.currentChapter.userConfig.customDataGroup)) {
                jsonAnnotation = JSON.parse(this.currentChapter.userConfig.customDataGroup);
            }

            if (this.currentChapter.userConfig.currentPageGroup) {
                currentPage = parseInt(this.currentChapter.userConfig.currentPageGroup);
            }
        } else {
            if (this.currentChapter.userConfig.customData && !this.isEmptyObject(this.currentChapter.userConfig.customData)) {
                jsonAnnotation = JSON.parse(this.currentChapter.userConfig.customData);
            }

            if (this.currentChapter.userConfig.currentPage) {
                currentPage = parseInt(this.currentChapter.userConfig.currentPage);
            }
        }

        if (jsonAnnotation) {
            // pdfviewer.importAnnotation(jsonAnnotation);
            this.viewer.importAnnotation(jsonAnnotation);
        }

        currentPage = currentPage > 0 ? currentPage : 1;
        this.viewer.navigation.goToPage(currentPage);
    }


    saveConfig() {
        // console.log(this.viewer);
        // console.log("saveConfig");

        this.booksService.getCurrentViewerConfig(this.viewer)
            .pipe(first())
            .subscribe(
                data => {
                    // console.log("saveConfig getCurrentViewerConfig", data);
                    if (this.isGroupMode) {
                        this.currentChapter.userConfig.customDataGroup = data;
                    } else {
                        this.currentChapter.userConfig.customData = data;
                    }

                    this.saveUserConfig();
                },
                error => {
                    console.log('ERROR...');
                });
    }

    annotationAdded(ev): void {
        // console.log('annotationAdded');
        this.hasUserChangesNotSave = true;
    }

    annotationRemove(ev): void {
        // console.log('annotationRemove');
        this.hasUserChangesNotSave = true;
    }

    annotationChange(ev): void {
        // console.log('annotationChange');
        this.hasUserChangesNotSave = true;
    }

    annotationTest(ev): void {
        // console.log("Annotation test", ev);
    }

    documentLoad(ev): void {
        // console.log("documentLoad", ev);
        this.import();
    }

    pageChange(ev): void {
        this.currentPage = ev.currentPageNumber;
        this.store.dispatch(new ViewerActions.SetGlobalPage(this.getGlobalPage(ev.currentPageNumber)));

        this.saveUserPage();

        this.refreshMaintenance();

    }

    saveUserConfig() {
        this.booksService.saveViewerConfig(this.getDataToSaveConfig())
            .pipe(first())
            .subscribe(
                data => {
                    this.hasUserChangesNotSave = false;
                },
                error => {
                    console.log('ERROR...');
                });
    }

    saveUserPage() {
        // console.log("saveUserPage");
        this.booksService.saveViewerConfig(this.getDataToSaveConfig())
            .pipe(first())
            .subscribe(
                data => {
                    this.hasUserChangesNotSave = false;
                },
                error => {
                    console.log('ERROR...');
                });
    }

    getDataToSaveConfig() {
        let data = {
            isGroupMode: this.isGroupMode ? 1 : 0,
            page_current: this.currentPage,
            custom_data: this.isGroupMode ? this.currentChapter.userConfig.customDataGroup : this.currentChapter.userConfig.customData,
            book_index_id: this.currentChapter.id,
            book_id: this.currentBook.id,
            chapter_id: this.currentChapter.id
        }

        // console.log("getDataToSaveConfig", data);

        return data;
    }


    clickAsideCollapsed(ev) {
        ev.preventDefault();

        this.store.dispatch(new LayoutActions.ToggleCollapsedResources());
    }

    toogleFullScreen() {
        if (this.isInFullScreenMode) {
            this.closeFullscreen();
        } else {
            this.openFullscreen();
        }
    }

    openFullscreen() {
        if (this.elem.requestFullscreen) {
            this.elem.requestFullscreen();
        } else if (this.elem.mozRequestFullScreen) {
            /* Firefox */
            this.elem.mozRequestFullScreen();
        } else if (this.elem.webkitRequestFullscreen) {
            /* Chrome, Safari and Opera */
            this.elem.webkitRequestFullscreen();
        } else if (this.elem.msRequestFullscreen) {
            /* IE/Edge */
            this.elem.msRequestFullscreen();
        }

        this.isInFullScreenMode = true;
    }

    closeFullscreen() {
        if (!this.isInFullScreenMode) {
            return;
        }

        if (this.document.exitFullscreen) {
            this.document.exitFullscreen();
        } else if (this.document.mozCancelFullScreen) {
            /* Firefox */
            this.document.mozCancelFullScreen();
        } else if (this.document.webkitExitFullscreen) {
            /* Chrome, Safari and Opera */
            this.document.webkitExitFullscreen();
        } else if (this.document.msExitFullscreen) {
            /* IE/Edge */
            this.document.msExitFullscreen();
        }

        this.isInFullScreenMode = false;
    }

    isPanelActive(type) {
        return this.openViewerPanelResources && this.panelResourcesTypeOpen == type;
    }

    openPanelResources(ev, type) {
        if (this.openViewerPanelResources && this.panelResourcesTypeOpen == type) {
            this.store.dispatch(new LayoutActions.ClosePanelViewerResources());
            return;
        }

        this.store.dispatch(new ViewerActions.SetPanelView({ view: type }));

        if (!this.openViewerPanelResources) {
            noScroll.on();
            this.store.dispatch(new LayoutActions.OpenPanelViewerResources());
        }
    }

    test() {
        // console.log('TEST...', this.viewer);
        this.exportAnnotation();

        // this.viewer.container.CommentPanelTool = true; 
    }

    toggleMobilePanel() {
        this.openOptionsMobileViewer = !this.openOptionsMobileViewer;
    }

    hasClassMobileMenu() {
        return !this.openOptionsMobileViewer && this.openModalViewer;
    }


}