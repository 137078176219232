// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,

    // apiUrl: 'http://eo.lan:8090/api',
    // imageCDN: 'http://eo.lan:8090',
    // web: 'http://eo.lan:8090',
    // apiUrl: 'https://dev.edicioneseo.es/api',
    // imageCDN: 'https://dev.edicioneseo.es',
    // web: 'https://dev.edicioneseo.es',
    apiUrl: 'https://edicioneseo.es/api',
    imageCDN: 'https://edicioneseo.es',
    web: 'https://edicioneseo.es',
    webCode: 'edicioneseo',

    baseResources: '/storage/resources/'
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
